import axios from "axios";

export const combinationGenerator = (lastGenerated) => {
  const englishAlphabet = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"];
  const alphabet = lastGenerated[0];
  const number = lastGenerated.slice(1);
  if (number < 99) {
    return `${alphabet}${parseInt(number) + 1}`;
  } else {
    const nextAlphabet = englishAlphabet[englishAlphabet.indexOf(alphabet) + 1];
    return `${nextAlphabet}1`;
  }
};

export const connection_url =
  "https://sheet.best/api/sheets/f916f444-c3de-4bbd-93c6-1712f45d2fe7";
export const ApiKey =
  "hvzwk3KTNTT6@mUpWWW6ea96zXGuSIDduS@AbHHV4-thKxCV_mc1KhOd!YsjaS2b";

export const url_configuration = {
  headers: {
    "X-Api-Key": ApiKey,
  },
};

export const handleSheetPostReq = async (payload) => {
  await axios
    .post(connection_url, { ...payload }, url_configuration)
    .catch((err) => console.log(err));
};
