import { Box, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { combinationGenerator } from "./post";

export function convertDateFormat(inputDate) {
  if (inputDate) {
    const parts = inputDate.split("-");
    if (parts.length === 3) {
      const [yy, mm, dd] = parts;
      // Convert to "dd-mm-yy" format
      return `${dd}-${mm}-${yy}`;
    }
  }
  // Return the original input if it's not in the expected format
  return inputDate;
}

const DialogBox = ({
  open,
  name,
  date,
  handleClose,
  serialNumber,
  discountPrice,
  minimumBilingPrice,
}) => {
  const formetDate = convertDateFormat(date);
  const image = "/template.png";
  const canvasRef = React.useRef(null);
  const [link, setLink] = useState(null);

  useEffect(() => {
    if (open) {
      const addTextToImage = () => {
        const canvas = canvasRef.current;
        const context = canvas?.getContext("2d");
        const img = new Image();

        img.onload = () => {
          if (canvas?.width) {
            canvas.width = img?.width;
            canvas.height = img?.height;
            context.drawImage(img, 0, 0);

            // text - 1
            context.fillStyle = "black";
            context.font = "600 70px Montserrat";
            context.fillText(name, 720, 1112);
            // text - 2
            context.fillStyle = "black";
            context.font = "600 50px Montserrat";
            context.fillText(formetDate, 1160, 1270);
            // text - 3 (rotated 90 degree)
            context.fillStyle = "white";
            context.font = "600 52px Montserrat";
            const thirdText = minimumBilingPrice;
            const textWidth = context.measureText(thirdText).width;
            context.save();
            context.translate(470, 900);
            context.rotate(-Math.PI / 2);
            context.fillText(`₹ ${thirdText}`, -textWidth / 2, 0);
            context.restore();
            // text-4
            context.fillStyle = "white";
            context.font = "600 30px Montserrat";
            context.fillText(combinationGenerator(serialNumber), 2435, 1640);
            // text -5
            context.fillStyle = "white";
            context.font = "600 115px Montserrat";
            const FifthText = discountPrice;
            const textWidth2 = context.measureText(FifthText).width;
            context.save();
            context.translate(380, 895);
            context.rotate(-Math.PI / 2);
            context.fillText(`₹ ${FifthText}`, -textWidth2 / 2, 0);
            context.restore();

            const dataURL = canvas.toDataURL("image/png");
            setLink(dataURL);
          }
        };

        img.src = image;
      };

      addTextToImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = link;
    a.download = "modified_image.png";
    a.click();
  };

  return (
    <Box
      zIndex={99}
      width={"100%"}
      height={"100%"}
      display={"flex"}
      alignItems={"center"}
      position={"absolute"}
      flexDirection={"column"}
      backgroundColor={"white"}
      justifyContent={"center"}
    >
      <Box maxWidth={"800px"} sx={{ p: 1 }}>
        <canvas ref={canvasRef} style={{ width: "100%" }}></canvas>
      </Box>
      <Box sx={{ m: 1 }}>
        <Button variant="contained" onClick={handleDownload}>
          Download
        </Button>
        <Button sx={{ ml: 2 }} onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default DialogBox;
