import "./App.css";
import SignInSide from "./signin.js";
function App() {
  return (
    <div className="App">
      <SignInSide />
    </div>
  );
}

export default App;
