import {
  connection_url,
  url_configuration,
  handleSheetPostReq,
  combinationGenerator,
} from "./post";
import axios from "axios";
import DialogBox from "./Box";
import Redeem from "./Redeem";
import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Select from "@mui/material/Select";
import { convertDateFormat } from "./Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import CssBaseline from "@mui/material/CssBaseline";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const defaultTheme = createTheme();
const default_form_data = {
  name: "",
  date: "",
  category: "",
  mobileNumber: "",
  serialNumber: "",
  discountPrice: "1000",
  minimumBilingPrice: "2500",
};

export default function SignInSide() {
  const [data, setData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState(default_form_data);

  React.useEffect(() => {
    setLoading(true);
    axios
      .get(connection_url, url_configuration)
      .then((response) => {
        setLoading(false);
        const rows = response.data.filter(
          (r) => r.Serial !== null && r.Serial !== ""
        );
        console.log(rows);
        setData(rows);
        const lastSerialNumber =
          rows.length === 0 ? "A0" : rows[rows?.length - 1]?.Serial;
        setFormData({ ...formData, serialNumber: lastSerialNumber });
      })
      .catch((errr) => {
        setLoading(false);
        console.log(errr);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    console.log({
      Name: formData.name,
      Phone: formData.mobileNumber,
      "Issue Date": new Date().toUTCString(),
      "Discount Price": formData.discountPrice,
      "Validity Date": formData.date.replace(/-/g, "/"),
      "Minimum biling price": formData.minimumBilingPrice,
      Serial: combinationGenerator(formData.serialNumber),
      Category: formData.category,
      "Redemption Status": "Available",
      "Redeemed By": " ",
    });
    e.preventDefault();
    setFormData(formData);
    setOpen(true);
    handleSheetPostReq({
      Name: formData.name,
      Phone: formData.mobileNumber,
      "Issue Date": new Date().toUTCString(),
      "Discount Price": formData.discountPrice,
      "Validity Date": convertDateFormat(formData.date),
      "Min Biling Price": formData.minimumBilingPrice,
      Serial: combinationGenerator(formData.serialNumber),
      Category: formData.category,
      "Redemption Status": "Available",
      "Redeemed By": " ",
    });
  };

  // this is special handler for category [select]
  const handleChange = (event) => {
    setFormData({ ...formData, category: event.target.value });
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {open && (
        <DialogBox open={open} handleClose={handleClose} {...formData} />
      )}

      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          sm={4}
          md={7}
          xs={false}
          sx={{
            backgroundImage:
              "url(https://ftvsalon.in/assets/img/salon/full-img.png)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <AddTaskIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Coupon Creating Widget
            </Typography>
            <div style={{ maxWidth: "100px" }}>
              {JSON.stringify(combinationGenerator(formData.serialNumber))}
            </div>

            <form onSubmit={handleSubmit}>
              <TextField
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleFormChange}
                variant="outlined"
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Mobile Number"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleFormChange}
                variant="outlined"
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="date"
                type="date"
                value={formData.date}
                onChange={handleFormChange}
                variant="outlined"
                fullWidth
                margin="normal"
                required
              />

              <TextField
                label="Discount Price"
                name="discountPrice"
                type="number"
                defaultValue={1000}
                value={formData.discountPrice}
                onChange={handleFormChange}
                variant="outlined"
                fullWidth
                margin="normal"
                required
              />

              <TextField
                label="Minimum Biling Price"
                name="minimumBilingPrice"
                type="number"
                defaultValue={2500}
                value={formData.minimumBilingPrice}
                onChange={handleFormChange}
                variant="outlined"
                fullWidth
                margin="normal"
                required
              />

              <FormControl variant="outlined" sx={{ my: 2, width: "100%" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Category
                </InputLabel>
                <Select
                  sx={{ width: "100%" }}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  onChange={handleChange}
                  label="Category"
                  value={formData.category}
                  placeholder="Category"
                >
                  <MenuItem value={"Radio"}>Radio</MenuItem>
                  <MenuItem value={"Newspaper"}>Newspaper</MenuItem>
                  <MenuItem value={"Events"}>Event</MenuItem>
                  <MenuItem value={"Salon"}>Salon</MenuItem>
                  <MenuItem value={"Social Media"}>Social Media</MenuItem>
                  <MenuItem value={"Others"}>Others</MenuItem>
                </Select>
              </FormControl>

              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                type="submit"
                sx={{ width: "100%", py: ".6rem" }}
              >
                Submit
              </Button>
            </form>
            <Redeem data={data} disabled={loading} />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
