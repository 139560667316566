import axios from "axios";
import React from "react";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { connection_url, url_configuration } from "./post";
import DialogContentText from "@mui/material/DialogContentText";

function dateStrToTimestamp(dateStr) {
  const [day, month, year] = dateStr.split("-");
  const dateObject = +new Date(year, month - 1, day); // Adjust the month to be zero-based
  return dateObject;
}

const default_form_data = {
  serial: "",
  redeemby: "",
  password: "",
};

const Redeem = ({ data, disabled }) => {
  const [model, setModel] = React.useState(false);
  const [formData, setFormData] = React.useState(default_form_data);

  const handleModelOpen = () => {
    setModel(true);
  };

  const handleModelClose = () => {
    setModel(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      if (formData.password === "FashionTV1234!") {
        const row = data.filter((n) => n.Serial === formData.serial).at(0);

        if (row["Redemption Status"] !== "Available") {
          alert("Coupon is either used or expired");
          window.location.reload();
        } else {
          if (
            +new Date() >
            dateStrToTimestamp(
              row["Validity Date"].split("/").reverse().join("-")
            )
          ) {
            alert("the coupon is already expired on validity date");
            row["Redeemed By"] = formData.redeemby;
            row["Redemption Status"] = "Expired";
            const connection_url_querty = `${connection_url}/Serial/*${formData.serial}*`;
            axios
              .put(connection_url_querty, row, url_configuration)
              .catch((err) => {
                alert("Failed!, please cheak serial number.");
                console.log(err);
              });
          } else {
            row["Redeemed By"] = formData.redeemby;
            row["Redemption Status"] = "Consumed";
            const connection_url_querty = `${connection_url}/Serial/*${formData.serial}*`;
            axios
              .put(connection_url_querty, row, url_configuration)
              .then(() => {
                alert("Sucessfully Redeemed!");
                window.location.reload();
              })
              .catch((err) => {
                alert("Failed!, please cheak serial number. ");
                console.log(err);
              });
          }
        }
      } else {
        alert("Please input right password!");
      }
    } catch (err) {
      window.location.reload();
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <>
      <Button
        variant="outlined"
        sx={{ marginTop: 5 }}
        onClick={handleModelOpen}
      >
        Redeem
      </Button>

      <Dialog
        open={model}
        onClose={handleModelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Redeemable Status"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={handleSubmit}>
              <TextField
                label="Serial Number"
                name="serial"
                value={formData.serial}
                onChange={handleFormChange}
                variant="outlined"
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Redeemed By"
                name="redeemby"
                value={formData.redeemby}
                onChange={handleFormChange}
                variant="outlined"
                fullWidth
                margin="normal"
                required
              />

              <TextField
                label="Password"
                name="password"
                value={formData.password}
                onChange={handleFormChange}
                variant="outlined"
                fullWidth
                margin="normal"
                required
              />

              <Button
                disabled={disabled}
                variant="contained"
                color="primary"
                type="submit"
                sx={{ width: "100%", py: ".6rem" }}
              >
                Redeem
              </Button>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Redeem;
